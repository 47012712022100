<template>
  <ComponentCreator
    componentName="ReturnsReturnGeneralForm"
    displayMethod="existing"
    componentType="form"
    :dispatchActions='{ init: "RETURN", save: "RETURN_EDIT", params: { init: false, key: "", value: currentId} }'
    :componentReload="false"
    :componentRedirect='{ init: false, data: "", path: ""}'
    currentData="currentReturn"
    :componentFilter='{ init: false, get: "" }'
    :componentToolbar='{ init: true, filter: false, actions: { init: true, title: "General", menu: {} } }'
    :currentId="currentId"
  />
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import ComponentCreator from '@/components/Component.vue';

export default defineComponent({
  components: {
    ComponentCreator,
  },
  setup(props, {emit}) {
    const route = useRoute();

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId,
    }
  }
})
</script>